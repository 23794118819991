<template>
  <div v-if="view" class="m-view-nav" :class="`m-view-nav--${navState}`">
    <m-icon
      id="views_nav_toggle"
      icon="arrow"
      :tooltip="toggleLabelTooltip"
      variant="secondary"
      hover="highlight"
      class="m-view-nav__toggle"
      :class="`m-view-nav__toggle--${toggleArrow}`"
      @click="toggleNavState"
    />
    <div class="m-view-nav__list">
      <div
        v-for="(element, i) in navList"
        :key="i"
        class="m-view-nav__wrapper"
        @mouseenter="keepDropdownOpen"
        @mouseleave="closeDropdown(element)"
      >
        <div
          :id="`m_views_nav_${element.value}`"
          tabindex="0"
          role="button"
          :data-tooltip-content="tooltip.content(element)"
          :data-tooltip-template="tooltip.template"
          :data-tooltip-position="tooltip.position"
          :data-tooltip-type="tooltip.type"
          class="m-view-nav__option m-clickable"
          :class="{
            'm-view-nav__option--active': isActive(element),
            '--a11y': appStore.a11y,
          }"
          @click="open(element)"
          @keydown.enter="open(element)"
        >
          <div class="m-view-nav__details">
            <m-icon
              :icon="element.icon"
              status="active"
              :tooltip="element.tooltip"
              :variant="
                navState == 'collapsed' && element.expanded
                  ? 'primary'
                  : 'terciary'
              "
              :weight="isActive(element) ? 'bold' : 'regular'"
              @click="open(element)"
            />
            <span v-show="navState == 'expanded'" class="mx-4 h5">
              {{ element.label }}
            </span>
          </div>
          <m-icon
            v-if="element.children?.length > 0 && navState == 'expanded'"
            :id="`${element.value}_icon`"
            icon="arrow"
            variant="secondary"
            status="active"
            :direction="
              expanded.find((f) => f == element.value) ? 'down' : 'up'
            "
            @click="toggleExpanded(element)"
          />
        </div>
        <div
          v-if="
            element.children?.length > 0 &&
            expanded.find((f) => f == element.value)
          "
          class="m-view-nav__children"
        >
          <div
            v-for="(child, ci) in element.children"
            :id="`m_views_nav_${element.value}_${child.value}`"
            :data-tooltip-content="child.description"
            data-tooltip-position="dynamic"
            :key="ci"
            tabindex="0"
            role="button"
            class="m-children__option"
            :class="[
              `m-children__option--${element.value}`,
              {
                'm-children__option--active': isChildActive(child.value),
                '--a11y': appStore.a11y,
              },
            ]"
            @click="openChild(element, child.value)"
            @keydown.enter="openChild(element, child.value)"
          >
            <span class="h5"> {{ child.label }}</span>
            <m-icon
              v-if="child.recurrent"
              id="automate"
              icon="report-automate"
              :tooltip="t('navigation.views.reports_recurrent')"
              variant="secondary"
              size="small"
              class="pa-2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import DateTimeUtils from "@utils/dateTime";
import { clone, kebabCase } from "lodash-es";

import MIcon from "@components/MIcon.vue";
import { useViewsStore } from "@root/store/modules/views";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useAppStore } from "@root/store/app";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useSessionStore } from "@root/store/modules/session";
import { useReportsStore } from "@root/store/modules/reports";
import {
  encodeQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";

const { t } = useI18n();
const viewsStore = useViewsStore();
const appStore = useAppStore();
const dashboardsStore = useDashboardsStore();
const filtersStore = useFiltersStore();
const reportsStore = useReportsStore();
const workspacesStore = useWorkspacesStore();
const sessionStore = useSessionStore();
const route = useRoute();
const { currentRoute, push } = useRouter();

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);
const view = computed(() => viewsStore.getViewById(viewId.value));

const dashboardId = computed(() => route.params.dashboardId);
const reportId = computed(() => route.params.reportId);

const navState = computed(() => sessionStore.secNavState);
const toggleNavState = () => {
  if (navState.value == "expanded") {
    sessionStore.secNavStateSet("collapsed");
    expanded.value.length = 0;
  } else sessionStore.secNavStateSet("expanded");
};

const expanded = ref([]);

const navList = computed(() => {
  const list = [
    "articles",
    "trending-entities",
    "entity-network",
    "saved",
    "dashboards",
    "reports",
  ];

  if (workspaceConfig.value?.metadata?.worksapceType !== "legal") {
    list.splice(1, 0, "tiles");
    list.splice(2, 0, "headlines");
  }

  return list.map((m) => {
    const object = {
      icon: kebabCase(m),
      value: m,
      label: t(`navigation.views.${m}`),
      description: t(`navigation.views.${m}_description`),
    };

    if (m == "dashboards" || m == "reports") {
      let list =
        m == "dashboards"
          ? dashboardsStore.getListByViewId(viewId.value)
          : reportsStore.getListByViewId(viewId.value);

      list = list?.map((el) => ({
        value: el.id,
        label: el.name ?? reportsStore.getNameById(el.id),
        recurrent: el.recurrent,
        created: DateTimeUtils.parseFromISO(el.created),
      }));

      const sort = viewsStore.sort;
      if (sort == "name") {
        object.children = list?.sort((a, b) => a.label.localeCompare(b.label));
      } else if (sort == "latest") {
        object.children = list?.sort(
          (a, b) => new Date(b.created) - new Date(a.created)
        );
      } else {
        object.children = list?.sort(
          (a, b) => new Date(a.created) - new Date(b.created)
        );
      }
    }
    return object;
  });
});

const isActive = (element) => {
  const active = currentRoute.value?.name;
  const from = currentRoute.value.meta.breadcrumbs;

  switch (element.value) {
    case "dashboards":
      return (
        ["dashboard", "dashboards-empty"].includes(active) ||
        from.includes("dashboard")
      );
    case "reports":
      return (
        ["report", "reports-empty"].includes(active) ||
        from.includes("report") ||
        from.includes("createReport")
      );
    default:
      return active == element.value || from.includes(element.value);
  }
};

const isChildActive = (val) => {
  const active = currentRoute.value?.name;

  const from = currentRoute.value.meta.breadcrumbs;

  return (
    active == val ||
    dashboardId.value == val ||
    reportId.value == val ||
    from.includes(val)
  );
};

watch(
  () => currentRoute.value?.name,
  (val) => {
    if (val.includes(["dashboard", "report"])) {
      if (
        navState.value == "expanded" &&
        !expanded.value.find((f) => f == val)
      ) {
        expanded.value.push(val);
      }
    }
  }
);

const toggleExpanded = (element) => {
  if (element.children?.length > 0) {
    const idx = expanded.value.findIndex((f) => f == element.value);
    if (idx > -1) {
      expanded.value.splice(idx, 1);
    } else {
      expanded.value.push(element.value);
    }
  }
};

const toggleArrow = computed(() => {
  if (navState.value == "collapsed") {
    return "right";
  } else return "left";
});

const toggleLabelTooltip = computed(() => {
  return {
    content: t(`navigation.views.${navState.value}`),
    position: "dynamic",
  };
});

const tooltip = computed(() => {
  if (navState.value == "collapsed") {
    return {
      content: (element) => {
        return JSON.stringify({ label: element.label });
      },
      template: "nav",
      position: "right",
      type: "blue",
    };
  } else {
    return {
      content: (element) => {
        if (["dashboards", "reports"].includes(element.value)) {
          const state = element.expanded ? "collapse" : "expand";
          return t(`navigation.views.${element.value}_${state}`);
        }
        return element.description;
      },
      position: "dynamic",
    };
  }
});

const open = (element) => {
  if (element.children?.length > 0) {
    const idx = expanded.value.findIndex((f) => f == element.value);
    if (idx > -1) {
      expanded.value.splice(idx, 1);
    } else {
      expanded.value.push(element.value);
    }
  }

  let qObjString = filtersStore.queryObjectRaw;
  const qObj = clone(filtersStore.queryObject);
  if (filtersStore.queryObject.contextualFilters) delete qObj.contextualFilters;

  if (["reports", "dashboards"].includes(element.value)) {
    // If navigating to reports or dashboard remove the regular filters, as we dont need them there
    if (filtersStore.queryObject.filters) delete qObj.filters;
  }
  qObjString = encodeQueryObject(qObj);

  push({
    name: element.value,
    params: { viewId: view.value?.id },
    query: {
      ...route.query,
      q: qObjString,
    },
  });
};

const openChild = (element, val) => {
  let qObjString = filtersStore.queryObjectRaw;
  const qObj = clone(filtersStore.queryObject);
  for (const property of ["contextualFilters", "filters"]) {
    if (filtersStore.queryObject[property]) {
      delete qObj[property];
    }
  }
  qObjString = encodeQueryObject(qObj);

  if (element.value == "dashboards") {
    push({
      name: "dashboard",
      params: { viewId: view.value.id, dashboardId: val },
      query: route.name === "dashboard" ? qObjString : undefined, //Manually keep the query filters in case the route is maintained
    });
  } else if (element.value == "reports") {
    push({
      name: "report",
      params: { viewId: view.value.id, reportId: val },
      query: route.name === "report" ? qObjString : undefined, //Manually keep the query filters in case the route is maintained
    });
  }
};

let timeout = undefined;

const keepDropdownOpen = () => {
  clearTimeout(timeout);
};

const closeDropdown = (element) => {
  if (navState.value == "collapsed") {
    return new Promise((res) => {
      timeout = setTimeout(() => {
        const idx = expanded.value.findIndex((f) => f == element.value);
        expanded.value.splice(idx, 1);
        res();
      }, 300);
    });
  }
};
</script>

<style scoped lang="scss">
.m-view-nav {
  min-width: $sidenav-width;
  width: $sidenav-width;
  padding: $spacing-0 $spacing-1;
  border: 1px solid color($sec-200);
  border-left: none;
  @include round-right-corners($spacing-1);
  position: relative;
  background-color: color($white);

  &__toggle {
    margin: $spacing-2 $spacing-1;
    @include round-corners;
    position: absolute;
    top: $spacing-0;
    right: $spacing-0;
    display: none;
    align-self: flex-end;
    background-color: color($white);
    @include elevate-button;

    &--right {
      transform: rotate(-90deg) translateY(73%);
    }

    &--left {
      transform: rotate(90deg) translateY(-73%);
    }
  }

  &:hover .m-view-nav__toggle {
    display: flex;
  }

  &__list {
    @include flex(flex-start, stretch, column);
  }

  &__option {
    height: $spacing-9;
    padding: $spacing-1 $spacing-2;
    margin: $spacing-1 $spacing-0;
    @include round-corners($spacing-1);
    @include flex(space-between, center, row);
    gap: $spacing-2;

    .h5,
    .m-icon {
      color: color($pri-action-inactive);
      fill: color($pri-action-inactive);
    }

    &--active {
      background-color: color($pri-action, 0.1);
      @include opacity-active;

      .m-icon {
        fill: color($pri-action);
        stroke: color($pri-action);
      }

      .h5 {
        font-weight: 700;
        color: color($pri-action);
      }
    }
  }

  &__details {
    @include flex(flex-start, center, row);
  }

  &__option:hover {
    .h5,
    .m-icon {
      color: color($pri-action);
      fill: color($pri-action);
    }
  }

  &__children {
    padding: $spacing-1 $spacing-2;
    margin-bottom: $spacing-1;
    @include round-corners($spacing-1);
    @include elevate-inset(color($pri-action-medium, 0.04));

    .m-children__option {
      padding: $spacing-2;
      @include flex(space-between, center, row);
      cursor: pointer;

      .h5 {
        color: color($pri-action-inactive);
        @include ellipsis;
        cursor: pointer;
      }

      &:hover {
        > svg {
          fill: color($pri-action);
          stroke: color($pri-action);
        }

        > .h5 {
          color: color($pri-action);
        }
      }

      &--active {
        > svg {
          fill: color($pri-action);
          stroke: color($pri-action);
        }

        > .h5 {
          font-weight: 700;
          color: color($pri-action);
        }
      }
    }
  }

  &--collapsed {
    min-width: $spacing-11;
    width: $spacing-11;
    max-width: $spacing-11;

    .m-view-nav {
      &__toggle {
        align-self: center;
      }

      &__option {
        padding: $spacing-1;
        justify-content: center;
      }

      &__children {
        border: 1px solid color($sec-200);
        @include round-corners($spacing-1);
        @include elevate-popover;
        position: absolute;
        background-color: color($sec-50);
        transform: translateX($spacing-2);

        .m-children__option {
          margin: $spacing-2;
          padding: $spacing-0 $spacing-2;
          @include round-corners($spacing-1);
        }
      }
    }
  }
}
</style>
