<template>
  <div v-if="banner" class="m-banner__wrapper">
    <div
      :id="`m_banner_${id}`"
      role="alert"
      class="m-banner"
      :class="[{ 'm-banner--out': exiting }, `m-banner--${variant}`]"
    >
      <m-icon
        :id="`m_banner_remove_${id}`"
        variant="terciary"
        icon="close"
        hover="elevate"
        class="m-banner__remove"
        @click="remove"
      />
      <div class="m-banner__content">
        <m-icon
          v-if="variant"
          :id="`m_banner_icon_${id}`"
          :variant="icon.variant"
          :icon="icon.name"
          status="active"
        />
        <p
          v-for="(msg, i) in message"
          :key="i"
          class="type--small"
          v-html="msg"
        ></p>
      </div>
    </div>
    <div v-if="queue.length > 1" class="m-counter">
      <p class="type--xsmall">{{ `+${queue.length - 1}` }}</p>
    </div>
  </div>
</template>
<script setup>
/*
 * Monitio Banner component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932642836/Banner
 */

import { ref, computed, watch } from "vue";

import MIcon from "@components/MIcon.vue";
import { snakeCase } from "lodash-es";
import { useAlertsStore } from "@root/store/modules/alerts";

const alertsStore = useAlertsStore();

const queue = computed(() => alertsStore.banner);
const banner = computed(() => {
  return queue.value.length ? queue.value[0] : null;
});

const id = computed(() => snakeCase(banner.value.id));
const variant = computed(() => banner.value?.variant ?? "default");

const exiting = ref(false);

const icon = computed(() => {
  const icon = { name: "", variant: "" };
  if (variant.value) {
    icon.variant = variant.value;
    switch (variant.value) {
      case "info":
        icon.name = variant.value;
        icon.variant = "primary";
        break;
      case "success":
        icon.name = "check";
        break;
      case "error":
        icon.name = "clear";
        break;
      case "warning":
        icon.name = variant.value;
    }
    return icon;
  }
  return null;
});

const message = computed(() => {
  let fullMessage = [];
  if (typeof banner.value.message == "string") {
    fullMessage.push(banner.value.message);
  } else if (Array.isArray(banner.value.message)) {
    fullMessage = banner.value.message;
  }
  return fullMessage;
});

watch(
  () => alertsStore.banner,
  (queue) => {
    queue.forEach((banner) => {
      if (banner.message) {
        if (
          typeof banner.message !== "string" &&
          !Array.isArray(banner.message)
        ) {
          console.error(
            `%cComponent id: m_banner_${banner.id}`,
            "font-weight:bold",
            `\n Invalid prop "banner": expected attribute "message" to be a string or array. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932642836/Banner for instructions on how to use the MBanner component.`
          );
        }
      } else {
        console.error(
          `%cComponent id: m_banner_${banner.id}`,
          "font-weight:bold",
          `\n Invalid prop "banner": expected attribute "message". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932642836/Banner for instructions on how to use the MBanner component.`
        );
      }
      if (banner.variant) {
        if (!["success", "error", "warning", "info"].includes(banner.variant)) {
          console.error(
            `%cComponent id: m_banner_${banner.id}`,
            "font-weight:bold",
            `\n Invalid prop "banner": expected attribute "variant" with value "success", "error", "warning" or "info" and got "${banner.variant}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932642836/Banner for instructions on how to use the MBanner component.`
          );
        }
      }
    });
  }
);

const remove = () => {
  if (queue.value.length === 1) {
    exiting.value = true;
  }
  alertsStore.remove(banner.value.id);
};
</script>

<style scoped lang="scss">
.m-banner {
  padding: $spacing-2;
  @include round-corners($spacing-1);
  @include flex(flex-start, flex-start, row);
  flex-grow: 1;
  position: relative;
  background-color: color($white);
  backdrop-filter: blur(2px);
  @include elevate-notification;
  animation: m-banner--in 0.4s ease-out;
  //white-space: nowrap;

  &__wrapper {
    width: 100vw;
    padding: $spacing-2;
    @include flex(center, center, row);
    gap: $spacing-2;
    position: absolute;
    top: $spacing-0;
    z-index: $z-dialog;
    overflow: hidden;
  }

  &--out {
    animation: m-banner--out 0.4s ease-out;
  }

  &--info {
    border: 1px solid color($pri-action-light, 0.05);
    background: linear-gradient(
      color($pri-action-light, 0.2),
      color($pri-action-light, 0.2)
    );
    background-color: color($white, 0.9);
  }

  &--success {
    border: 1px solid color($success-light, 0.05);
    background-color: color($success-light, 0.9);
  }

  &--warning {
    border: 1px solid color($warning-light, 0.05);
    background-color: color($warning-light, 0.9);
  }

  &--error {
    border: 1px solid color($error-light, 0.05);
    background-color: color($error-light, 0.9);
  }

  &__icon {
    margin-top: math.div($spacing-1, 2);
  }

  &__remove {
    padding: $spacing-0;
    @include round-corners;
    display: none;
    position: absolute;
    top: $spacing-0;
    right: $spacing-0;
    background-color: color(white);
    @include elevate-button;
    transform: translate($spacing-2, -$spacing-2);
  }

  &:hover .m-banner__remove {
    @include flex(center, center, row);
  }

  &__content {
    width: 100%;
    @include flex(center, center, row);
    gap: $spacing-2;
  }
}

.m-counter {
  padding: $spacing-3;
  @include round-corners($spacing-1);
  @include flex(center, center, row);
  background-color: color($white, 0.8);
  backdrop-filter: blur(2px);
  @include elevate-notification;
}

@keyframes m-banner--in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes m-banner--out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
</style>
@root/utils/
