<template>
  <div
    v-if="store.hasInit && !workspacesStore.switching"
    class="m-main"
    id="m-main"
  >
    <m-nav />
    <div
      class="m-main__content"
      :class="`m-main__content--${sessionStore.mainNavState}`"
    >
      <router-view />
      <m-chat v-if="appStore.chat == 'pin'" />
    </div>
  </div>
  <m-loading v-else type="intro" />

  <m-toast-wrapper />
  <m-banner />
  <m-modals-register />
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  watchEffect,
  onBeforeUnmount,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useSignalR } from "@hubs/main";
import { interceptors } from "@api/api";
import { useMonitioStore } from "@store/store";
import { useRouter, useRoute } from "vue-router";
import { isBrowserCompatible } from "@utils/isBrowserCompatible";
import { Logger } from "@priberam/loggerjs";
import { useTooltip } from "@tooltips/useTooltip";

import useOnboardingModal from "@root/components/modals/MOnboarding/useOnboardingModal";
import useUpdateModal from "@root/components/modals/MUpdate/useUpdateModal";
import MModalsRegister from "@components/modals/MModalsRegister.vue";

import MNav from "@components/navigation/MNav.vue";
import MChat from "@components/MChat.vue";
import MLoading from "@components/MLoading.vue";
import MToastWrapper from "@root/components/alerts/MToastWrapper.vue";
import MBanner from "@components/alerts/MBanner.vue";
import { useReportsStore } from "@root/store/modules/reports";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useAppStore } from "@root/store/app";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useSessionStore } from "@root/store/modules/session";
import useArticlesCSVExportReact from "@root/hubs/reactions/useArticlesCSVExportReact";
import { useWindowSize } from "@vueuse/core";

const { t, locale } = useI18n();
const { currentRoute } = useRouter();
const route = useRoute();
const router = useRouter();
const signalR = useSignalR();
const appStore = useAppStore();
const store = useMonitioStore();
const userStore = useUserStore();
const viewsStore = useViewsStore();
const alertsStore = useAlertsStore();
const reportsStore = useReportsStore();
const dashboardsStore = useDashboardsStore();
const workspacesStore = useWorkspacesStore();
const sessionStore = useSessionStore();
const { initializeTooltipService } = useTooltip();
const { width: windowWidth, height: windowHeight } = useWindowSize();

const keyMovement = (evt) => {
  if (evt.key == "Tab" || evt.key.startsWith("Arrow")) {
    appStore.setA11y(true);
  }
};

const mouseMovement = () => {
  appStore.setA11y(false);
};

const openChat = (evt) => {
  if (evt?.target?.attributes?.id?.value == "open_chat") {
    if (appStore.chat == "close") {
      appStore.setChat("open");
    } else document.getElementById("m_chat_input")?.focus();
  }
};

const { open: openOnboardingModal } = useOnboardingModal();

onMounted(async () => {
  await signalR.init();
  await store.appInit();

  //signalr.addEventListener(appStore.workspaceId, () => undefined);

  if (userStore.i18nLanguage) locale.value = userStore.i18nLanguage;

  /**
   * Attempt to detect viewId in the URL, this can
   * have many names the the route  */
  // let view = null;
  // if (route.params.viewId && route.params.viewId != "") {
  //   view = viewsStore.getViewById(route.params.viewId);
  // }
  // if (route.params.tempViewId && route.params.tempViewId != "") {
  //   view = viewsStore.getViewById(route.params.tempViewId);
  // }
  // await viewsStore.selectView(view);

  document.body.addEventListener("click", openChat);
  document.body.addEventListener("keydown", keyMovement);
  document.body.addEventListener("mousemove", mouseMovement);
  initializeTooltipService();

  if (!userStore.getToursTaken.includes("onboarding")) openOnboardingModal();
});

onBeforeUnmount(() => {
  document.body.removeEventListener("click", openChat);
  document.body.removeEventListener("keydown", keyMovement);
  document.body.removeEventListener("mousemove", mouseMovement);
});

watchEffect(() => {
  const classList = document.documentElement.classList;
  const currentRouteName = currentRoute.value?.name;
  const classeListSlipted = [...classList];

  const hasFilterPane = ![
    "home",
    "article",
    "summary",
    "saved",
    "report",
    "manageFilters",
    "createManageFilters",
    "dashboards",
    "dashboardsTemplates",
    "dashboardBuilder",
    "reports",
    "reportsTemplates",
    "reportBuilder",
  ].includes(currentRouteName);

  if (classeListSlipted.length) {
    classeListSlipted.forEach((fe) => classList.remove(fe));
  }

  if (appStore.fullscreen) {
    classList.add("--fullscreen");
  } else if (currentRoute.value.path.includes("builder")) {
    classList.add("--toolbox");
  } else if (hasFilterPane) {
    if (userStore.pinnedPane) classList.add("--pinned-pane");
    else classList.add("--unpinned-pane");
  }

  classList.add(`--theme-${userStore.theme}`);
});

const logger = new Logger({
  domain: window._env_.VUE_APP_LOGGERSERVER_DOMAIN,
  port: window._env_.VUE_APP_LOGGERSERVER_PORT,
  protocol: window._env_.VUE_APP_LOGGERSERVER_PROTOCOL,
  proxy: {
    proxy: window._env_.VUE_APP_USEPROXY,
    path: window._env_.VUE_APP_PROXYPATH,
  },
});

//#region 🚿 User logs
/** This used to be in router.js, but due to th new store we must have this code
    in a component, else the context of the store is always undefined */
/* if (!isBrowserCompatible() && window._env_.DEPLOYMENT_MODE != "DEVELOPMENT") {
  const browserUnsupportedBanner = {
    type: "banner",
    variant: "warning",
    message: `${t("general.alerts.banner.browserUnsupported_title")}. ${t(
      "general.alerts.banner.browserUnsupported_message"
    )}`,
  };

  alertsStore.add(browserUnsupportedBanner);

  setInterval(() => {
    const existingBanner = alertsStore.banner.find(
      (banner) => banner.message == browserUnsupportedBanner.message
    );
    if (!existingBanner) {
      alertsStore.add(browserUnsupportedBanner);
    }
  }, 600000);
} */

const { react } = useArticlesCSVExportReact();
onMounted(() => {
  if (window._env_.DEPLOYMENT_MODE == "DEVELOPMENT") {
    const community = window._env_.VUE_APP_apiUrl.includes("community");
    alertsStore.add({
      id: "backend",
      type: "banner",
      variant: "info",
      message: t("general.alerts.banner.backend_title", {
        link: community ? "Community" : window._env_.VUE_APP_apiUrl,
      }),
    });

    setTimeout(() => {
      alertsStore.remove("backend");
    }, 1500);
  }

  signalR.addEventListener("changed", react);
});

watch(
  () => [windowWidth.value, windowHeight.value],
  ([width, height]) => {
    if (
      (width < 1280 || height < 600) &&
      window._env_.DEPLOYMENT_MODE != "DEVELOPMENT"
    ) {
      alertsStore.add({
        id: "windowSizeUnsupported",
        type: "banner",
        variant: "error",
        message: t("general.alerts.banner.windowSizeUnsupported_title"),
      });
    } else {
      alertsStore.remove("windowSizeUnsupported");
    }
  },
  { immediate: true }
);

// Verificar a cada 1 minuto
router.beforeEach((to, from) => {
  // Log page visits. This does not log the first loaded page once the user visits the app
  // TODO: configure new log pages
  const pagesToLog = [];

  if (pagesToLog.includes(to.name) && from.name) {
    logger.Log({
      AppName: "Monitio",
      RequestUrl: window.location.href,
      ElapsedTime: "00:00:00",
      AppUserId: store.user?.id,
      AppOrganizationId: store.user?.idorg,
      AppUserEmail: store.user?.email,
      LogRecord: JSON.stringify({
        viewName: to.name,
        viewId: to.params.viewid,
        dashboardName: to.name,
      }),
      Level: "INFO",
      EventName: "page:visit",
    });
  }
  // End of logging
});
//#endregion

//#region Monitio Application update
const ui_version = computed({
  get() {
    return appStore.uiVersion;
  },
  set(val) {
    appStore.setUiVersion(val);
  },
});

const platform_version = computed({
  get() {
    return appStore.platformVersion;
  },
  set(val) {
    appStore.setPlatformVersion(val);
  },
});

const newVersion = ref(false);
const { open: openUpdateModal } = useUpdateModal();

interceptors.request((config) => {
  config.headers = {
    ...config.headers,
    "pba-connection-id": signalR.connectionId,
  };
});

interceptors.response(
  (response) => {
    const incoming_platform_version = response.headers["x-monitio-ver"];
    if (incoming_platform_version)
      platform_version.value = incoming_platform_version;
    //UI version update notification logic
    const incoming_ui_version = response.headers["x-monitio-ui-ver"];
    if (incoming_ui_version) {
      if (ui_version.value && ui_version.value != incoming_ui_version) {
        openUpdateModal();
        newVersion.value = true;
      }
      ui_version.value = incoming_ui_version;
    }
    // End of UI version update notification implementation
    return response;
  },
  (error) => {
    return error;
  }
);
//#endregion
</script>

<style scoped lang="scss">
.m-main {
  width: 100vw;
  height: 100vh;
  @include flex(flex-start, flex-start, row);
  overflow: hidden;

  &__content {
    max-width: 100%;
    align-self: stretch;
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>
