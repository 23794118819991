<template>
  <div id="m_header_breadcrumb" class="m-header-breadcrumbs">
    <div v-for="(crumb, idx) in breadcrumbs" :key="idx" class="m-crumb">
      <m-icon
        v-if="idx != 0"
        icon="arrow"
        variant="secondary"
        direction="right"
        size="small"
        :class="{ 'mx-1': windowWidth >= 1280 }"
        disabled
      />

      <div
        :id="`m_header_breadcrumb_${crumb.value}_crumb`"
        :tabindex="
          crumb.value == 'aggregate' || crumb.value == 'analyze' ? -1 : 0
        "
        :aria-labelledby="`m_header_breadcrumb_${snakeCase(crumb.value)}`"
        :role="crumb.value == 'views' && idx == 0 ? 'combobox' : 'button'"
        class="m-crumb__details"
        :class="[
          `m-details__${crumb.value}`,
          {
            '--active': idx == breadcrumbs.length - 1,
            '--not-found': cantRedirect(crumb),
            '--a11y': appStore.a11y,
          },
        ]"
        @click="open(crumb)"
        @keyup.enter="open(crumb)"
        @mouseleave="closeDropdown"
      >
        <m-loading
          v-if="crumb.value == 'article' || crumb.value == 'cluster'"
          size="xsmall"
        />
        <component
          v-else-if="crumb.label || crumb.title"
          :id="`m_header_breadcrumb_${snakeCase(crumb.value)}`"
          :is="idx == breadcrumbs.length - 1 ? 'h4' : 'h5'"
          class="type--small"
        >
          {{ crumb.label ?? crumb.title }}
        </component>
        <m-dropdown
          ref="dropdownRef"
          :id="`m_header_breadcrumb_${snakeCase(crumb.value)}`"
          :visible="openDropdown == crumb.value"
          :labelledBy="`m_header_breadcrumb_${snakeCase(crumb.value)}`"
          :options="crumbOptions(crumb)"
          size="small"
          @update:visible="(val) => toggleDropdown(crumb, val)"
          @update:selected="(val) => selectOpt(val)"
          @mouseenter="keepDropdownOpen"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";

import { useWindowSize } from "@vueuse/core";
import useDropdown from "@hooks/useDropdown";
import MIcon from "@components/MIcon.vue";
import MLoading from "@components/MLoading.vue";
import MDropdown from "@components/MDropdown.vue";

import { debounce, snakeCase } from "lodash-es";
import { useAppStore } from "@root/store/app";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useNavigationStore } from "@root/store/modules/navigation";
import { useViewsStore } from "@root/store/modules/views";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useReportsStore } from "@root/store/modules/reports";

const { t } = useI18n();
const appStore = useAppStore();
const workspacesStore = useWorkspacesStore();
const navigationStore = useNavigationStore();
const viewsStore = useViewsStore();
const dashboardsStore = useDashboardsStore();
const reportsStore = useReportsStore();
const { currentRoute, push } = useRouter();
const route = useRoute();
const { width: windowWidth } = useWindowSize();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);

const { openDropdown, keepDropdownOpen, closeDropdown } = useDropdown();

const breadcrumbs = computed(() => {
  return navigationStore.path;
});

const getBreadcrumbs = (to, from) => {
  const path =
    to.meta.breadcrumbs?.map((m) => {
      const view =
        viewsStore.getViewById(to.params.tempViewId) ||
        viewsStore.getViewById(to.params.viewId);
      let label;
      switch (m) {
        case "home":
        case "views":
        case "templates":
        case "settings":
        case "notFound":
          return {
            value: m,
            label: t(`navigation.header.${m}`),
          };
        case "view":
          return {
            value: to.params.viewId || to.params.tempViewId,
            label: view?.name ?? t("general.errors.accessDenied"),
            routeParams: to?.params,
            type: m,
            canAccess: view?.name ? true : false,
          };
        case "dashboard":
          return {
            value: m,
            label: dashboardsStore.getById(to.params?.dashboardId)?.name,
            routeParams: to?.params,
            type: m,
          };
        case "report":
          return {
            value: m,
            label: reportsStore.getNameById(to.params?.reportId),
            routeParams: to?.params,
            type: m,
          };
        default:
          if (m == "divider") {
            label = "";
          } else label = t(`navigation.header.${to.meta.breadcrumbs[0]}_${m}`);
          return {
            value: m,
            label: label,
            routeParams: to?.params,
          };
      }
    }) ?? [];

  if (path[1]?.canAccess === false) {
    navigationStore.setPath();
    navigationStore.setPageTitle([
      { value: "accessDenied", label: t("general.errors.accessDenied") },
    ]);
  } else {
    navigationStore.setPath(path);
    navigationStore.setPageTitle(path);
  }
};

onMounted(() => {
  getBreadcrumbs(currentRoute.value);
});

onBeforeRouteUpdate(
  debounce((to, from) => {
    getBreadcrumbs(to, from);
  }),
  window._env_.VUE_APP_DEFAULT_FILTER_DEBOUNCE
);

const cantRedirect = (crumb) => {
  return [
    "folder",
    "settings",
    "general",
    "workspace",
    "cluster",
    "templates",
  ].includes(crumb.value);
};

const hasDropdown = (crumb) => {
  return ["view", "dashboard", "report"].includes(crumb.type);
};

const open = (crumb) => {
  if (hasDropdown(crumb)) {
    openDropdown.value = crumb.value;
  } else if (!cantRedirect(crumb)) {
    push({ name: crumb.value, params: crumb.routeParams });
  }
};

const toggleDropdown = (crumb, val) => {
  if (val) openDropdown.value = crumb.value;
  else closeDropdown();
};

const crumbOptions = (crumb) => {
  let arr = [];
  if (hasDropdown(crumb)) {
    switch (crumb.type) {
      case "view":
        arr = viewsStore.getAllViews;
        break;
      case "dashboard":
        arr = dashboardsStore.getAll;
        break;
      case "report":
        arr = reportsStore.getAllTemplates;
        break;
    }
  }
  return arr.map((m) => ({
    ...m,
    label: m.name ?? m.template.title,
    value: m.id,
    type: crumb.type,
    params: crumb.routeParams,
  }));
};

const selectOpt = (opt) => {
  if (opt.type == "view") push(`/views/${opt.id}/tiles`);
  else push(`/views/${opt.params.viewId}/${opt.type}s/${opt.id}`);
};
</script>

<style scoped lang="scss">
.m-header-breadcrumbs {
  margin-left: $spacing-3;
  @include flex(flex-start, center, row);

  *,
  :deep(*) {
    white-space: nowrap;
  }
  .m-crumb {
    min-height: $spacing-7;
    @include flex(flex-start, center, row);

    .m-crumb__details {
      padding: $spacing-1 $spacing-2;
      @include round-corners($spacing-1);
      @include flex(flex-start, center, row);
      flex-wrap: nowrap;
      position: relative;

      h5,
      :deep(.m-icon) {
        color: color($text-dark);
        fill: color($text-dark);
      }

      h4,
      h5 {
        @include ellipsis;
      }

      &:not(.--not-found):hover,
      &:not(.--not-found).--hover {
        cursor: pointer;

        h4,
        h5,
        :deep(.m-icon) {
          color: color($pri-action);
          fill: color($pri-action);
          cursor: pointer;
        }
      }

      &:not(.--not-found):hover {
        background-color: color($pri-action-light, 0.2);
      }

      &.--active {
        background-color: color($pri-action-light, 0.4);

        h4,
        :deep(.m-icon) {
          color: color($pri-action);
          fill: color($pri-action);
          cursor: pointer;
        }
      }
    }
  }
}

:deep(.m-loading) {
  transform: scale(0.7);
}

:deep(.m-loading) {
  transform: scale(0.7);
}
.m-hint {
  transform: translate(18px, -4px);
}

@include mq(desktopXS) {
  .m-crumb__details:not(:first-child),
  .m-details__cluster {
    max-width: $column-2;
  }
}

@include mq(desktopS) {
  .m-crumb__details:not(:first-child),
  .m-details__cluster {
    max-width: $column-2;
  }
}

@include mq(desktopM) {
  .m-crumb__details:not(:first-child),
  .m-details__cluster {
    max-width: $column-2;
  }
}

@include mq(desktopL) {
  .m-crumb__details:not(:first-child),
  .m-details__cluster {
    max-width: $column-3;
  }
}

@include mq(desktopXL) {
  .m-crumb__details:not(:first-child),
  .m-details__cluster {
    max-width: $column-5;
  }
}
</style>
