<template>
  <div class="m-modal__body" data-test="m_modal_create_view">
    <m-input
      id="create_view_name"
      v-model="folder.name"
      type="text"
      :label="t('createView.name')"
      :placeholder="t('createView.name')"
      :validation="validateFolderName"
      maxlength="32"
      required
      class="mt-3 mb-8"
      @resolve="finishedEditing"
    />
  </div>
  <div class="m-modal__footer">
    <m-button
      v-if="slotProps.cancel"
      id="m_modal_cancel"
      :label="t('general.buttons.cancel')"
      type="text"
      variant="terciary"
      class="mt-3"
      @click="cancel"
    />
    <m-button
      id="m_modal_continue"
      :label="t('createView.buttons_resolve')"
      type="contained"
      variant="primary"
      class="mt-3 ml-6"
      :disabled="disabled"
      :data-tooltip-content="disabled ? t('createView.addViewName') : undefined"
      data-tooltip-position="dynamic"
      @click="create"
    />
  </div>
  <!-- <modal-regist name="m_dialog_tour_create_view"></modal-regist> -->
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { useRouter, useRoute } from "vue-router";
import MIcon from "@components/MIcon.vue";
import MInput from "@components/MInput.vue";
import MButton from "@components/MButton.vue";
import MFilterBadge from "@components/MFilterBadge.vue";
import MTextarea from "@components/MTextarea.vue";
import MPictureUpload from "@components/MPictureUpload.vue";
import Guid from "@utils/guid";
import structuredClone from "@utils/structuredClone";
import { checkForWikiImage } from "@utils/utils";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { cloneDeep } from "lodash-es";

interface Props {
  slotProps: {
    close?: () => void;
    cancel?: () => void;
  };
}

const props = defineProps<Props>();

const { t } = useI18n();
const { api } = useApi();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const alertsStore = useAlertsStore();
const workspacesStore = useWorkspacesStore();
const { push } = useRouter();
const route = useRoute();

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);
const viewFilters = ref([]);

const folder = reactive({
  name: "",
});

const currentFolderNames = computed(() => {
  return userStore.details.folders?.map((x) => x.name);
});

const validateFolderName = computed(() => {
  if (currentFolderNames.value?.includes(folder.name)) {
    return {
      type: "error",
      label: t("createView.viewNameAlreadyExists"),
    };
  }
  return null;
});

const userLocale = computed(() => userStore.i18nLanguage);
const userLang = computed(() => userLocale.value.split("-")[0]);

/** If there's empty fields disable the buttons */
const disabled = computed(() => {
  return (
    (folder.name == "" || folder.name == " " || !!validateFolderName.value) ??
    true
  );
});

const clearFields = () => {
  folder.name = "";
};

const create = async () => {
  try {
    await userStore.createWorkspaceUserFolder({
      name: folder.name,
    });
    props.slotProps.close?.();
  } catch (error) {
    alertsStore.add({
      type: "toast",
      variant: "error",
      message:
        "Somethng went wrong while adding folder. Please try again later.",
    });
    props.slotProps.cancel?.();
  }
};

const cancel = async () => {
  props.slotProps.cancel?.();
};

const finishedEditing = () => {
  if (!disabled.value) {
    create();
  }
};

const infoTooltip = computed(() => {
  return {
    content: t("createView.filters_info"),
    position: "left",
  };
});
</script>

<style scoped lang="scss">
.m-modal__body {
  @include flex(flex-start, stretch, column);

  .m-filters {
    &__entities {
      height: 10vh;
      padding: $spacing-2;
      @include round-corners($spacing-1);
      @include flex(flex-start, stretch, column);
      gap: $spacing-1;
      @include elevate-inset;
      overflow-y: auto;
    }

    &__header {
      @include flex(space-between, center, row);

      .m-icon--info {
        margin-top: auto;
        margin-bottom: $spacing-1;
      }
    }
  }

  :deep(.m-textarea__content textarea) {
    min-height: $spacing-9;
  }
}
.m-hint {
  transform: translate(3px, 25px) !important;
  z-index: 1;
}
</style>
