import { useI18n } from "vue-i18n";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";

export default () => {
  const { t } = useI18n();
  const { open, close, cancel } = useModal("m-modal");

  const handleModal = (view) => {
    open("m_modal_share_view", {
      heading: t("views.share.modal_title"),
      description: t("views.share.modal_description"),
      width: 5,
      id: "m_modal_share_view",
      buttons: ["custom"],
      close,
      cancel,
      view: view,
    }).then(
      (reason) => {
        console.info(`resolved with ${JSON.stringify(reason)}`);
      },
      (reason) => {
        console.error(`rejected with ${JSON.stringify(reason)}`);
      }
    );
    // searchRef.value.focus();
  };
  return { open: handleModal, close, cancel };
};
