<template>
  <div class="m-main-nav" :class="[`m-main-nav--${navState}`]">
    <m-icon
      id="views_nav_toggle"
      icon="arrow"
      :tooltip="toggleLabelTooltip"
      variant="secondary"
      hover="highlight"
      class="m-main-nav__toggle"
      :class="`m-main-nav__toggle--${toggleArrow}`"
      @click="toggleNavState"
    />
    <div class="m-main-nav__list">
      <m-logo :type="logoType" height="24" class="m-main-nav__logo" />
      <div
        id="m_main_nav_home"
        :data-tooltip-content="getTooltip('home').content"
        :data-tooltip-template="getTooltip().template"
        :data-tooltip-position="getTooltip().position"
        :data-tooltip-type="getTooltip().type"
        class="m-main-nav__home m-clickable"
        :class="{ 'm-main-nav__home--active': currentRoute.name == 'home' }"
        @click="goToHome"
      >
        <div
          v-if="currentRoute.name == 'home'"
          class="m-main-nav__marker"
        ></div>
        <m-icon
          id="m_main_nav_home"
          icon="home"
          variant="sidenav"
          :weight="currentRoute.name == 'home' ? 'bold' : 'regular'"
          status="active"
          @click="push('/home')"
        />
        <span v-if="navState == 'expanded'" class="h5">
          {{ t("navigation.main.home") }}
        </span>
      </div>
      <div class="m-divider"></div>
      <div v-if="navState == 'expanded'" class="m-main-nav__views">
        <div class="d-flex-start-center">
          <m-icon
            id="m_main_nav_views"
            icon="views"
            variant="sidenav"
            status="active"
          />
          <span class="h5">
            {{ t("navigation.main.views") }}
          </span>
        </div>
        <m-icon
          v-if="navState == 'expanded'"
          id="m_main_nav_create_view"
          icon="add-outlined"
          variant="sidenav"
          size="small"
          hover="highlight"
          ref="createViewIcon"
          @click="openCreateOptionsDropdown = true"
        />
      </div>
      <div
        v-if="navState == 'collapsed'"
        id="m_main_nav_create_view"
        :data-tooltip-content="getTooltip('create').content"
        :data-tooltip-template="getTooltip().template"
        :data-tooltip-position="getTooltip().position"
        :data-tooltip-type="getTooltip().type"
        class="m-main-nav__create-view m-clickable"
        @click="openCreateOptionsDropdown = true"
      >
        <m-icon
          id="m_main_nav_create_view"
          icon="add-outlined"
          ref="createViewIcon"
          variant="sidenav"
          @click="openCreateOptionsDropdown = true"
        />
      </div>
      <m-dropdown
        v-if="createViewIcon"
        id="m_main_nav_create_dropdown"
        labelledBy="m_main_nav_create_label"
        :options="createDropdownOptions"
        v-model:visible="openCreateOptionsDropdown"
        floating
        :keyup="keyup"
        :keydown="keydown"
        fullwidth
        :parent="createViewIcon.ref"
        class="m-create__dropdown"
        @update:selected="openCreate"
        @keep-dropdown-open="keepCreateOptionsDropdownOpen"
        @mouseenter="keepCreateOptionsDropdownOpen"
        @mouseleave="closeCreateOptionsDropdown"
      />
      <m-search
        v-if="navState == 'expanded'"
        id="m_main_nav_views_search"
        v-model:query="query"
        size="small"
        :placeholder="t('navigation.main.views_searchPlaceholder')"
        class="my-2"
      />
      <!-- <div v-for="(folder, i) in folders" :key="i" class=""></div> -->
      <div class="m-main-nav__views-wrapper">
        <draggable
          class="dragArea"
          v-model="viewsAndFoldersStructure"
          item-key="id"
          v-bind="{
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
          }"
        >
          >
          <template #item="{ element }">
            <div>
              <!-- If backendId is defined it means its a view -->
              <m-main-nav-view-item v-if="element.backendId" :view="element" />
              <!-- Else its a folder -->
              <m-main-nav-folder-item
                v-else
                @item-added="itemAddedToFolder"
                @item-removed="itemRemovedFromFolder"
                @items-changed="itemsChangedInFolder"
                :folder="element"
              />
            </div>
          </template>
        </draggable>
        <div v-if="query.trim().length > 1 && views.length == 0">
          <span
            class="mx-2 h6 type--small type--empty"
            :class="`type--${size}`"
          >
            {{ t("navigation.main.views_empty") }}
          </span>
        </div>
      </div>
      <div class="m-divider"></div>
      <div
        ref="templatesRef"
        id="m_main_nav_templates"
        :data-tooltip-content="getTooltip('templates').content"
        :data-tooltip-template="getTooltip().template"
        :data-tooltip-position="getTooltip().position"
        :data-tooltip-type="getTooltip().type"
        class="m-main-nav__template m-clickable"
        :class="{
          'm-main-nav__template--active':
            currentRoute.path.startsWith('/template'),
        }"
        @click="toggleTemplatesDropdown"
        @keyup.up="openTemplatesDropdown = true"
        @keyup.down.exact="openTemplatesDropdown = true"
        @keyup.alt.down="openTemplatesDropdown = true"
        @keyup.home="openTemplatesDropdown = true"
        @keyup.end="openTemplatesDropdown = true"
        @keyup.space="openTemplatesDropdown = true"
        @keyup.enter="openTemplatesDropdown = true"
        @keyup.esc="
          () => {
            closeTemplatesDropdown();
            closeCreateOptionsDropdown();
          }
        "
      >
        <div
          v-if="currentRoute.path.startsWith('/templates')"
          class="m-main-nav__marker"
        ></div>
        <div class="d-flex-start-center">
          <m-icon
            id="m_main_nav_templates"
            icon="construction"
            variant="sidenav"
            :weight="
              currentRoute.path.startsWith('/templates') ? 'bold' : 'regular'
            "
            status="active"
            @click="toggleTemplatesDropdown"
          />
          <span
            v-if="navState == 'expanded'"
            id="m_main_nav_templates_label"
            class="h5"
          >
            {{ t("navigation.main.templates") }}
          </span>
        </div>
        <m-icon
          v-if="navState == 'expanded'"
          id="m_main_nav_templates_arrow"
          icon="arrow"
          variant="sidenav"
          size="small"
          @click="toggleTemplatesDropdown"
        />
        <m-dropdown
          id="m_main_nav_templates_dropdown"
          labelledBy="m_main_nav_templates_label"
          :options="templatesOptions"
          v-model:visible="openTemplatesDropdown"
          floating
          :keyup="keyup"
          :keydown="keydown"
          fullwidth
          :parent="templatesRef"
          class="m-templates__dropdown"
          @update:selected="openTemplates"
          @keep-dropdown-open="keepTemplatesDropdownOpen"
          @mouseenter="keepTemplatesDropdownOpen"
          @mouseleave="closeTemplatesDropdown"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useSignalR } from "@root/hubs/main";
import useViewReact from "@root/hubs/reactions/useViewReact";
import useCreateViewModal from "@components/modals/MCreateView/useCreateViewModal";
import useDropdown from "@hooks/useDropdown";
import draggable from "vuedraggable";
import MLogo from "@components/MLogo.vue";
import MSearch from "@components/MSearch.vue";
import MIcon from "@components/MIcon.vue";
import MDropdown from "@components/MDropdown.vue";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useSessionStore } from "@root/store/modules/session";
import MMainNavViewItem from "./MMainNavViewItem.vue";
import MMainNavFolderItem from "./MMainNavFolderItem.vue";
import structuredClone from "@root/utils/structuredClone";
import { debounce } from "lodash-es";
import useCreateFolderModal from "../modals/MCreateFolder/useCreateFolderModal";

const { t } = useI18n();
const { currentRoute, push } = useRouter();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const sessionStore = useSessionStore();
const workspacesStore = useWorkspacesStore();
const signalR = useSignalR();
const workspaceId = ref(workspacesStore.id);
const templatesRef = ref(null);

const { open: openCreateView } = useCreateViewModal();
const { open: openCreateFolder } = useCreateFolderModal();

const {
  openDropdown: openTemplatesDropdown,
  toggleDropdown: toggleTemplatesDropdown,
  keepDropdownOpen: keepTemplatesDropdownOpen,
  closeDropdown: closeTemplatesDropdown,
} = useDropdown();

const {
  openDropdown: openCreateOptionsDropdown,
  toggleDropdown: toggleCreateOptionsDropdown,
  keepDropdownOpen: keepCreateOptionsDropdownOpen,
  closeDropdown: closeCreateOptionsDropdown,
} = useDropdown();

const createViewIcon = ref();

const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  keyup.value = evt;
};

const keyDownMovement = (evt) => {
  keydown.value = evt;
};

onMounted(() => {
  templatesRef.value?.addEventListener("keyup", keyUpMovement);
  templatesRef.value?.addEventListener("keydown", keyDownMovement);
});

onBeforeUnmount(() => {
  templatesRef.value?.removeEventListener("keyup", keyUpMovement);
  templatesRef.value?.removeEventListener("keydown", keyDownMovement);
});

const navState = computed(() => sessionStore.mainNavState);
const toggleNavState = () => {
  if (navState.value == "expanded") {
    sessionStore.mainNavStateSet("collapsed");
  } else sessionStore.mainNavStateSet("expanded");
};

const toggleArrow = computed(() => {
  if (navState.value == "collapsed") {
    return "right";
  } else return "left";
});

const toggleLabelTooltip = computed(() => {
  return {
    content: t(`navigation.main.${navState.value}`),
    position: "dynamic",
  };
});

const logoType = computed(() => {
  if (navState.value == "collapsed") {
    return "ico";
  } else return "horizontal";
});

const query = ref("");

const views = computed(() => {
  const baseViews = viewsStore.userViews
    .concat(viewsStore.sharedViews)
    .filter(
      (f) =>
        f.isBaseView && f.name.toLowerCase().includes(query.value.toLowerCase())
    )
    .map((m) => ({ ...m, readonly: true }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const allViews = viewsStore.userViews.concat(viewsStore.sharedViews);

  const draftViews = viewsStore.draftViews
    .filter(
      (f) =>
        !f.isBaseView &&
        f.name.toLowerCase().includes(query.value.toLowerCase()) &&
        f.workspaceId == workspaceId.value
    )
    .map((m) => ({ ...m, readonly: true }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const organizedViews = allViews
    //.filter((f) => !f.isBaseView) //Filter out the base view
    .filter((f) => !f.folder && !f.isBaseView)
    .filter((f) => f.name.toLowerCase().includes(query.value.toLowerCase()))
    .map((m) => ({ ...m, readonly: m.isShared }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return baseViews.concat(draftViews.concat(organizedViews));
});

const isDragging = ref(false);
/** @type {import("vue").ComputedRef<(import("@root/types").Monitio.View | import("@root/types").Monitio.WorkspaceUserFolder)[]>} */
const viewsAndFoldersStructure = computed({
  get() {
    const folders = structuredClone(userStore.details.folders).sort(
      (a, b) => (a.metadata?.order ?? 0) - (b.metadata?.order ?? 0)
    );

    for (const view of viewsStore.getAllViews.filter(
      (x) => x.workspaceUserFolderId
    )) {
      // Insert the views on the respective folder
      const folder = folders.find((x) => x.id === view.workspaceUserFolderId);
      if (!folder) continue;
      folder.items ??= [];
      if (folder.items.some((x) => x.id == view.id)) continue;
      folder.items.push(view);
    }
    for (const innerFolder of folders.filter((x) => x.parentFolderId)) {
      // Insert the views on the respective folder
      const folder = folders.find((x) => x.id === innerFolder.parentFolderId);
      if (!folder) continue;
      folder.items ??= [];
      if (folder.items.some((x) => x.id == innerFolder.id)) continue;
      folder.items.push(innerFolder);
    }

    /** @type {(import("@root/types").Monitio.View | import("@root/types").Monitio.WorkspaceUserFolder)[]}*/
    let result = folders.filter((x) => !x.parentFolderId);
    // Insert views that have no folder on top
    result = result.concat(
      viewsStore.getAllViews.filter((x) => !x.workspaceUserFolderId)
    );

    result.sort(
      (a, b) =>
        (a.order ?? a.metadata?.order ?? -1) -
        (b.order ?? b.metadata?.order ?? -1)
    );
    return result;
  },
  set(val) {
    const touchedIds = new Set();

    /**
     * @description Recursive function that loops through the items recursivly and update the store
     * @param {(import("@root/types.api.local").MonitioAPI.ViewDTO | import("@root/types.api.local").MonitioAPI.WorkspaceUserFolderDTO)[]} items
     * @param {import("@root/types.api.local").MonitioAPI.WorkspaceUserFolderDTO?} parentFolderId
     */
    const iterator = (items, parentFolderId = null) => {
      const innerFolders = new Map(); // Add inner folder that we have to iterate recursivly here
      items.forEach((item, idx) => {
        if (touchedIds.has(item.id)) return;
        touchedIds.add(item.id);
        if (viewsStore.userViews.some((x) => x.id === item.id)) {
          /** @type {import("@root/types.api.local").MonitioAPI.ViewDTO} */
          const view = viewsStore.userViews.find((x) => x.id === item.id);
          view.workspaceUserFolderId = parentFolderId;
          if (!parentFolderId) view.order = idx; //Only update order of top level
        }

        if (viewsStore.sharedViews.some((x) => x.id === item.id)) {
          /** @type {import("@root/types.api.local").MonitioAPI.ViewDTO} */
          const view = viewsStore.sharedViews.find((x) => x.id === item.id);
          view.workspaceUserFolderId = parentFolderId;
          if (!parentFolderId) view.order = idx; //Only update order of top level
        }

        if (userStore.details.folders.some((x) => x.id === item.id)) {
          /** @type {import("@root/types.api.local").MonitioAPI.WorkspaceUserFolderDTO} */
          const folder = userStore.details.folders.find(
            (x) => x.id === item.id
          );
          folder.parentFolderId = parentFolderId;
          if (!parentFolderId) {
            //Only update order of top level
            folder.metadata ??= {};
            folder.metadata.order = idx;
          }
          if (item.items) innerFolders.set(folder.id, item.items);
        }
      });

      innerFolders.forEach((items, folderId) => {
        iterator(items, folderId);
      });
    };

    iterator(val);
  },
});

const goToHome = () => {
  window._env_.CLEAR_FILTERS = true;
  push("/home");
};

watch(
  () => viewsAndFoldersStructure.value,
  (val) => {
    updateOrders();
  }
);

const updateOrders = debounce(() => {
  // Persist changes to the database
  userStore.updateFoldersOrder();
  viewsStore.updateViewsOrderAndFolders();
}, 100);

const itemAddedToFolder = (id, folderId, items) => {
  items.forEach((item, idx) => {
    if (viewsStore.userViews.some((x) => x.id === item.id)) {
      const view = viewsStore.userViews.find((x) => x.id === item.id);
      view.order = idx;
      if (item.id === id) view.workspaceUserFolderId = folderId;
    }

    if (viewsStore.sharedViews.some((x) => x.id === item.id)) {
      const view = viewsStore.sharedViews.find((x) => x.id === item.id);
      view.order = idx;
      if (item.id === id) view.workspaceUserFolderId = folderId;
    }

    if (userStore.details.folders.some((x) => x.id === item.id)) {
      const folder = userStore.details.folders.find((x) => x.id === item.id);
      folder.metadata.order = idx;
      if (item.id === id) folder.parentFolderId = folderId;
    }
  });
};

/**
 * @description Recursive function that loops through the items recursivly and update the store
 * @param {string} folderId
 * @param {(import("@root/types.api.local").MonitioAPI.ViewDTO | import("@root/types.api.local").MonitioAPI.WorkspaceUserFolderDTO)[]} items
 */
const itemsChangedInFolder = (folderId, items) => {
  viewsStore.userViews
    .filter((x) => x.workspaceUserFolderId === folderId)
    .forEach((view) => {
      const idx = items.findIndex((x) => x.id === view.id);
      if (idx >= 0) view.order = idx;
    });
  viewsStore.sharedViews
    .filter((x) => x.workspaceUserFolderId === folderId)
    .forEach((view) => {
      const idx = items.findIndex((x) => x.id === view.id);
      if (idx >= 0) view.order = idx;
    });
  userStore.details.folders
    .filter((x) => x.parentFolderId === folderId)
    .forEach((folder) => {
      const idx = items.findIndex((x) => x.id === folder.id);
      if (idx >= 0) folder.metadata.order = idx;
    });
};

const itemRemovedFromFolder = (id, folderId) => {
  // This case is handled by the computed set of viewsAndFoldersStructure
  return;
};

const getTooltip = (val) => {
  if (navState.value == "collapsed") {
    let label = "";
    switch (val) {
      case "home":
        label = t("navigation.main.home_collapsed_action");
        break;
      case "toggle":
        label = t("navigation.main.collapsed");
        break;
      case "create":
        label = t("navigation.main.views_collapsed_create");
        break;
      case "templates":
        label = t("navigation.main.templates_collapsed");
        break;
    }
    return {
      template: "nav",
      content: JSON.stringify({ label }),
      position: "right",
      type: "blue",
    };
  } else {
    let label = "";
    switch (val) {
      case "home":
        label = t(`navigation.main.home_expanded_action`);
        break;
      case "toggle":
        label = t("navigation.main.expanded");
        break;
      case "create":
        label = t("navigation.main.views_expanded_create");
        break;
    }
    return {
      content: label,
      template: null,
      position: "dynamic",
    };
  }
};

const createFolder = () => {
  // TODO
};

const createDropdownOptions = computed(() => {
  const opts = ["view-new", "folder-new"];
  return opts.map((m) => ({
    icon: m,
    value: m,
    label: t(`navigation.main.${m}`),
  }));
});

const openCreate = (val) => {
  switch (val.value) {
    case "view-new":
      openCreateView();
      break;
    case "folder-new":
      openCreateFolder();
      break;
  }
};

const templatesOptions = computed(() => {
  const opts = ["dashboards", "reports"];

  return opts.map((m) => ({
    icon: m,
    value: m,
    label: t(`navigation.main.templates_${m}`),
  }));
});

const openTemplates = (val) => {
  push({ name: `${val.value}Templates` });
};

//#region Websockets events handling
const { reactToChange, reactToDelete } = useViewReact();
onMounted(() => {
  signalR.addEventListener("changed", reactToChange);
  signalR.addEventListener("delete", reactToDelete);
});

onUnmounted(() => {
  signalR.removeEventListener("changed", reactToChange);
  signalR.removeEventListener("changed", reactToDelete);
});
//#endregion
</script>

<style scoped lang="scss">
.m-main-nav {
  min-width: $sidenav-width;
  width: $sidenav-width;
  padding: $spacing-2 $spacing-1 $spacing-0;
  border: 1px solid color($pri-action-medium, 0.1);
  @include round-corners($spacing-1);
  position: relative;
  background-color: color($pri-ultra-light);

  &__toggle {
    margin: $spacing-2 $spacing-1;
    @include round-corners;
    position: absolute;
    top: $spacing-0;
    right: $spacing-0;
    display: none;
    align-self: flex-end;
    background-color: color($white);
    @include elevate-button;

    &--right {
      transform: rotate(-90deg) translateY(73%);
    }

    &--left {
      transform: rotate(90deg) translateY(-73%);
    }
  }

  &:hover .m-main-nav__toggle {
    display: flex;
  }

  &__list {
    max-height: calc(100vh - $spacing-2);
    @include flex(flex-start, stretch, column);
  }

  &__logo {
    margin: $spacing-1 math.div($spacing-3, 2) $spacing-2;
    align-self: flex-start;
    transform: scale(1.17);
    transform-origin: left;
  }

  &__marker {
    width: math.div($spacing-1, 2);
    height: $spacing-6;
    @include round-corners($spacing-1);
    position: absolute;
    left: 0;
    background-color: color($pri-action);
    transform: translateX(-$spacing-1);
  }

  .m-divider {
    opacity: 0.5;
  }

  &__home,
  &__views,
  &__create-view,
  &__view,
  &__template {
    height: $spacing-9;
    margin: $spacing-1 $spacing-0;
    @include round-corners($spacing-1);
    position: relative;
    @include flex(space-between, center, row);
    gap: $spacing-2;

    .h5 {
      color: color($pri-action-inactive);
      @include one-line-ellipsis;
    }

    &--active {
      background-color: color($pri-action, 0.1);
      @include opacity-active;

      .m-icon {
        color: color($pri-action);
        fill: color($pri-action);
        stroke: color($pri-action);
      }

      .h5 {
        font-weight: 700;
        color: color($pri-action);
      }
    }
  }

  &__home {
    justify-content: flex-start;
  }

  &__create-view {
    justify-content: center;
  }

  &__views .d-flex-start-center,
  &__template .d-flex-start-center {
    gap: $spacing-2;
  }

  &__home,
  &__create-view,
  &__views,
  &__template {
    padding: $spacing-1 $spacing-2;

    &:not(.m-main-nav__views):hover {
      .h5,
      .m-icon {
        color: color($pri-action);
        fill: color($pri-action);
      }
    }
  }

  &__views-wrapper {
    width: calc(100% + $spacing-4);
    height: calc(100vh - (($spacing-14 + $spacing-1) * 4));
    max-height: calc(100vh - (($spacing-14 + $spacing-1) * 4));
    padding-left: $spacing-2;
    padding-right: $spacing-2;
    flex-grow: 1;
    transform: translateX(-$spacing-2);
    overflow-x: auto;
  }

  &__view {
    height: $spacing-11;
    padding: $spacing-1;

    > div {
      @include flex(flex-start, center, row);
      gap: $spacing-3;
    }

    .h6.type--xsmall {
      opacity: 0.6;
    }

    .m-view {
      &__badge .m-image {
        width: $spacing-9;
        height: $spacing-9;
        position: relative;
        aspect-ratio: 1 / 1;
        object-fit: cover;
      }

      &__thumbnail {
        border: 1px solid color($pri-action, 0);
        @include round-corners($spacing-1);
        overflow: hidden;
      }

      &__count {
        min-width: $spacing-6;
        height: $spacing-6;
        border: 2px solid color($white);
        @include round-corners($spacing-7);
        @include flex(center, center, row);
        position: absolute;
        right: $spacing-0;
        bottom: $spacing-0;
        transform: translate(math.div($spacing-1, 2), math.div($spacing-1, 2));
        background-color: color($red);

        span {
          font-size: 0.625rem;
          line-height: 0.75rem;
          color: color($white);
        }
      }

      &__actions {
        display: none;
      }
    }

    &:hover {
      .m-view {
        &__thumbnail {
          border: 1px solid color($pri-action, 0.3);
        }

        &__details span.h5 {
          color: color($pri-action);
          fill: color($pri-action);
        }

        &__actions {
          @include flex(flex-start, center, row);
          gap: $spacing-1;
        }
      }
    }
  }

  &--collapsed {
    min-width: $sidenav-width--collapsed;
    width: $sidenav-width--collapsed;
    .m-main-nav {
      &__logo {
        transform: scale(1) translateX(2px);
      }

      &__home,
      &__views,
      &__view,
      &__template {
        padding: $spacing-1;
        @include flex(center, center, row);
        gap: $spacing-0;
      }

      &__views-wrapper {
        height: calc(100vh - ($spacing-12 * 4));
        max-height: calc(100vh - ($spacing-12 * 4));
      }
    }
  }
}

.m-templates__dropdown {
  border: 1px solid color($pri-action-medium, 0.1);
  background-color: color($pri-ultra-light);

  .h6 {
    color: color($pri-action-inactive);
    font-weight: 500;
  }

  .m-icon {
    fill: color($pri-action-inactive);
    stroke-width: 0 !important;
  }
}
</style>
