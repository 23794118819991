<template>
  <div
    :id="`m_main_nav_view_${snakeCase(view.id)}`"
    :data-tooltip-content="getTooltip(view).content"
    :data-tooltip-template="getTooltip(view).template"
    :data-tooltip-position="getTooltip(view).position"
    :data-tooltip-type="getTooltip(view).type"
    class="m-main-nav__view m-clickable"
    :class="{
      'm-main-nav__view--active': isActive(view.id!),
    }"
    @mouseenter="setNewsletterState(view.newsletterSubscribed ?? false)"
    @click="openView(view)"
    @keyup.enter="openView(view)"
  >
    <div>
      <div v-if="isActive(view.id!)" class="m-main-nav__marker"></div>
      <div class="m-view__badge">
        <m-image
          :src="view.details?.image ?? undefined"
          :fallback="monitio_thumbnail"
          width="32"
          height="32"
          class="m-view__thumbnail"
        />
        <div v-if="limitCount(view.count ?? 0)" class="m-view__count">
          <span class="h4">
            {{ limitCount(view.count ?? 0) }}
          </span>
        </div>
      </div>
      <div v-if="navState == 'expanded'" class="m-view__details">
        <span class="h5">{{ view.name }}</span>
        <span
          v-if="view.isBaseView"
          class="h6 type--xsmall d-flex-start-center"
        >
          {{ t("navigation.main.views_view_base") }}
        </span>
        <span
          v-else-if="view.isDraft"
          class="h6 type--xsmall d-flex-start-center"
        >
          <m-icon
            :id="`m_main_nav_view_draft_${snakeCase(view.id)}`"
            icon="edit"
            size="xsmall"
            variant="terciary"
            weight="light"
            status="active"
            class="mr-1"
          />
          {{ t("navigation.main.views_view_draft") }}
        </span>
        <!-- <span
                  v-else-if="getShareInfo(view)"
                  class="h6 type--xsmall d-flex-start-center"
                >
                  <m-icon
                    :id="`m_main_nav_view_share_${snakeCase(view.id)}`"
                    :icon="getShareInfo(view).icon"
                    size="xsmall"
                    variant="terciary"
                    weight="light"
                    status="active"
                    class="mr-1"
                  />
                  {{ getShareInfo(view).label }}
                </span> -->
      </div>
    </div>
    <div v-if="navState == 'expanded'">
      <div class="m-view__actions">
        <m-icon
          v-if="canAccessNewsletter(view)"
          :id="`m_main_nav_${snakeCase(view.id)}_newsletter`"
          :icon="newsletterState"
          variant="secondary"
          size="small"
          hover="highlight"
          :data-tooltip-content="newsletterStateTooltip"
          @mouseenter="(evt) => toggleNewsletterState(evt, view)"
          @mouseleave="(evt) => toggleNewsletterState(evt, view)"
          @click="toggleNewsletter(view)"
        />
        <m-icon
          v-if="!view.isBaseView && view.isShared"
          id="m_main_nav_views_leave"
          :tooltip="newsletterTooltip"
          icon="unshare"
          variant="secondary"
          size="small"
          hover="highlight"
          @click="toggleNewsletter(view)"
        />
        <m-options
          v-if="!view.isBaseView && !view.isShared"
          id="m_main_nav_views_options"
          variant="secondary"
          :options="viewOptions(view)"
          size="small"
          floating
          disableSort
          class="m-clickable"
          @select="(opt) => viewSelectOpt(view, opt)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import { useApi } from "@api/api";
import { clone, snakeCase } from "lodash-es";

import useMShareViewModal from "@components/modals/MShareView/useMShareViewModal";
import useMViewSettingsModal from "@components/modals/MViewSettings/useMViewSettingsModal";

//@ts-ignore
import monitio_thumbnail from "@assets/images/monitio_thumbnail.png";
import MImage from "@components/MImage.vue";
import MIcon from "@components/MIcon.vue";
import MOptions from "@components/MOptions.vue";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useReportsStore } from "@root/store/modules/reports";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useSessionStore } from "@root/store/modules/session";
import type { MonitioAPI } from "@root/types.api.local";
import type { Monitio } from "@root/types";
import {
  encodeQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";

const { t } = useI18n();
const { currentRoute, push } = useRouter();
const route = useRoute();
const userStore = useUserStore();
const viewsStore = useViewsStore();
const alertsStore = useAlertsStore();
const filtersStore = useFiltersStore();
const reportsStore = useReportsStore();
const sessionStore = useSessionStore();
const dashboardsStore = useDashboardsStore();
const workspacesStore = useWorkspacesStore();
const { api } = useApi();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);

const { open: openShareView } = useMShareViewModal();
const { open: openViewSettings } = useMViewSettingsModal();

interface Props {
  view: Monitio.View;
}

const props = defineProps<Props>();

const navState = computed(() => sessionStore.mainNavState);

const limitCount = (val: number) => {
  if (val > 99) {
    return "+99";
  } else return val;
};

const getShareInfo = (view: Monitio.View) => {
  let info = null;
  if (view?.isShared) {
    const user = workspacesStore.getUserById(view.createdById!);
    const firstName = user?.accountDetails?.firstName;
    const lastName = user?.accountDetails?.lastName;

    info = {
      icon: "view-shared",
      label: t("navigation.main.views_view_owner", {
        name: `${firstName} ${lastName}`,
      }),
    };
  } else if (
    (view?.sharedWith?.targets?.team?.length ?? 0) > 0 &&
    (view?.sharedWith?.targets?.workspaceUser?.length ?? 0) > 0
  ) {
    const users =
      workspaceConfig.value?.teams?.filter((x) =>
        view.sharedWith!.targets!.workspaceUser!.map((m) => m.id).includes(x.id)
      ) ?? [];
    const teams =
      workspaceConfig.value?.teams?.filter((x) =>
        view.sharedWith!.targets!.team!.map((m) => m.id).includes(x.id)
      ) ?? [];
    info = {
      icon: "views-shared",
      list: users.concat(teams).sort((a, b) => a.name!.localeCompare(b.name!)),
      label: t("navigation.main.views_view_shared", {
        count: users.concat(teams).length,
      }),
    };
  } else if ((view?.sharedWith?.targets?.team?.length ?? 0) > 0) {
    const teams =
      workspaceConfig.value?.teams?.filter((x) =>
        view.sharedWith!.targets!.team!.map((m) => m.id).includes(x.id)
      ) ?? [];
    info = {
      icon: "views-shared",
      list: teams.sort((a, b) => a.name!.localeCompare(b.name!)),
      label: t("navigation.main.views_view_shared", {
        count: teams.length,
      }),
    };
  } else if ((view?.sharedWith?.targets?.workspaceUser?.length ?? 0) > 0) {
    const users =
      workspaceConfig.value?.teams?.filter((x) =>
        view.sharedWith!.targets!.workspaceUser!.map((m) => m.id).includes(x.id)
      ) ?? [];
    info = {
      icon: "views-shared",
      list: users.sort((a, b) => a.name!.localeCompare(b.name!)),
      label: t("navigation.main.views_view_shared", {
        count: users.length,
      }),
    };
  }
  return info;
};

const canAccessNewsletter = (view: Monitio.View) => {
  if (view?.isDraft) return false;
  /** If its not shared, the user is the owner */
  if (!view?.isShared) return true;
  /** Check here if the view is shared directly with the user */
  if (
    view?.sharedWith?.targets?.workspaceUser?.find(
      (f) => f.id == userStore.details.id
    )
  ) {
    return true;
  }

  /** Next, check if one user is team leader / ownner of any of the teams that this view is shared with */
  const { accountId, teams } = userStore.details;
  const sharedWithUserTeams = teams?.filter((x) =>
    view.sharedWith?.targets?.team?.find((f) => f.id === x.id)
  );
  if (
    sharedWithUserTeams?.length &&
    sharedWithUserTeams?.some((x) => x.ownerId == accountId)
  ) {
    return true;
  }

  /** If none pass forbid */
  return false;
};

const changingNewsletter = ref(false);
const newsletterState = ref("newsletter-off");

const newsletterStateTooltip = computed(() => {
  switch (newsletterState.value) {
    case "newsletter-on":
      return t("navigation.main.views_view_newsletter-on");
    case "newsletter-off":
    default:
      return t("navigation.main.views_view_newsletter-off");
  }
});

const setNewsletterState = (state: boolean) => {
  if (state) newsletterState.value = "newsletter-on";
  else newsletterState.value = "newsletter-off";
};

const toggleNewsletterState = (evt: Event, view: Monitio.View) => {
  const state = view.newsletterSubscribed;

  if (
    (state && newsletterState.value == "newsletter-on") ||
    (!state && newsletterState.value == "newsletter-off")
  ) {
    if (evt.type == "mouseleave") return;
  }
  if (newsletterState.value == "newsletter-on") {
    newsletterState.value = "newsletter-off";
  } else newsletterState.value = "newsletter-on";
};

const toggleNewsletter = async (view: Monitio.View) => {
  const state = view.newsletterSubscribed;
  if (
    (state && newsletterState.value == "newsletter-on") ||
    (!state && newsletterState.value == "newsletter-off")
  ) {
    return;
  }
  changingNewsletter.value = true;
  if (view?.newsletterSubscribed) {
    const request = await api.views!.unsubscribeNewsletter(view.id!);
    if (request.status != 200) return;
    const v = { ...view };
    v.newsletterSubscribed = false;
    viewsStore.updateView(v);
  } else {
    const request = await api.views!.subscribeNewsletter(view.id!);
    if (request.status != 200) return;
    const v = { ...view };
    v.newsletterSubscribed = true;
    viewsStore.updateView(v);
  }
  changingNewsletter.value = false;
};

const newsletterTooltip = computed(() => {
  return t(`navigation.main.views_${newsletterState.value}`);
});

const viewOptions = (view: Monitio.View) => {
  let list;
  if (view.isDraft) {
    list = ["delete"];
  } else list = ["edit", "manage-filters", "share", "delete"];

  return list.map((m) => ({
    icon: m,
    value: m,
    label: t(`navigation.main.views_view_options_${m}`),
  }));
};

const getTooltip = (val: Monitio.View) => {
  if (navState.value == "collapsed") {
    let template = "nav";
    const content = {
      label: val.name,
      info: val.name,
    };
    if (val.isBaseView) {
      content.info = t("navigation.main.views_view_base");
    } else if (val.isDraft) {
      content.info = t("navigation.main.views_view_draft");
    } else if (getShareInfo(val)) {
      content.info = getShareInfo(val)?.label;
      if (getShareInfo(val)?.list?.length) {
        content.info = getShareInfo(val)?.label;
      }
    }

    if (val?.isBaseView) template = "baseView";
    else if (val?.isDraft) template = "draftView";
    else if (getShareInfo(val)?.list?.length) template = "sharedView";
    if (getShareInfo(val)) template = "viewShared";

    return {
      template: template,
      content: JSON.stringify(content),
      position: "right",
      type: "blue",
    };
  } else {
    return {
      content: t("navigation.main.views_view_open", { name: val.name }),
      template: null,
      position: "dynamic",
    };
  }
};

const openDeleteViewDialog = (view: MonitioAPI.ViewDTO) => {
  // console.log("delete", view.id);
  const resolveDialog = () => {
    if (currentRoute.value.params.viewId == view.id) {
      push("/home");
    }

    if (view.isDraft) {
      viewsStore.removeDraftViewById(view.id!);
    } else viewsStore.removeViewById(view.id!);

    alertsStore.add({
      type: "toast",
      variant: "success",
      message: t("general.alerts.toast.deletedView", {
        name: view.name,
      }),
    });
  };

  alertsStore.add({
    type: "dialog",
    variant: "error",
    heading: t("general.alerts.dialog.deleteView.heading", {
      name: view.name,
    }),
    message: t("general.alerts.dialog.deleteView.message"),
    resolve: {
      label: t("general.buttons.delete"),
      action: resolveDialog,
    },
    cancel: t("general.buttons.keep"),
  });
};

const viewSelectOpt = (view: MonitioAPI.ViewDTO, opt: string) => {
  switch (opt) {
    case "share":
      openShareView(view);
      break;
    case "edit":
      openViewSettings(view);
      break;
    case "manage-filters":
      push({ name: "manageFilters", params: { viewId: view.id } });
      break;
    case "delete":
      openDeleteViewDialog(view);
      break;
  }
};

const isActive = (val: string) => {
  if (currentRoute.value.name == "createManageFilters") {
    return currentRoute.value.params.tempViewId == val;
  } else return currentRoute.value.params.viewId == val;
};

const openView = (view: MonitioAPI.ViewDTO) => {
  if (view.isDraft) {
    const viewId = route.params.viewId ?? workspaceConfig.value.baseViewId;
    push(`/views/${viewId}/create-view/${view.id}/manage-filters`);
    // viewsStore.selectView(props.element);
  } else if (view.id) {
    let qObjString = filtersStore.queryObjectRaw;
    if (filtersStore.queryObject.contextualFilters) {
      const qObj = clone(filtersStore.queryObject);
      delete qObj.contextualFilters;
      qObjString = encodeQueryObject(qObj);
    }

    let exists;
    switch (currentRoute.value.name) {
      // These cases are a white list of routes that support view switching within them
      case "articles":
      case "tiles":
      case "headlines":
      case "trending-entities":
      case "entity-network":
      case "saved":
        push({
          path: `/views/${view.id}/${currentRoute.value.name}`,
          query: {
            ...currentRoute.value.query,
            q: qObjString,
          },
        });
        break;
      case "report":
        // eslint-disable-next-line no-case-declarations
        const templateId = reportsStore.getById(
          currentRoute.value.params.reportId as string
        )?.template?.id;
        exists = reportsStore
          .getListByViewId(view.id)
          ?.find((f) => f.template?.id == templateId);

        push({
          name: exists ? "report" : "report-not-found",
          params: {
            ...currentRoute.value.params,
            reportId: exists?.id,
            viewId: view.id,
          },
          query: { ...currentRoute.value.query, q: qObjString },
        });
        break;
      case "dashboard":
        exists = dashboardsStore
          .getListByViewId(view.id)
          .find((f) => f.id == currentRoute.value.params.dashboardId);
        push({
          name: exists ? "dashboard" : "dashboard-not-found",
          params: {
            ...currentRoute.value.params,
            viewId: view.id,
          },
          query: { ...currentRoute.value.query, q: qObjString },
        });
        break;
      default:
        push({
          name:
            workspaceConfig.value.metadata?.worksapceType !== "legal"
              ? "tiles"
              : "articles",
          query: { ...currentRoute.value.query, q: qObjString },
          params: {
            viewId: view.id,
          },
        });
        break;
    }
  }
};
</script>

<style scoped lang="scss">
.m-main-nav {
  min-width: $sidenav-width;
  width: $sidenav-width;
  padding: $spacing-2 $spacing-1 $spacing-0;
  border: 1px solid color($pri-action-medium, 0.1);
  @include round-corners($spacing-1);
  position: relative;
  background-color: color($pri-ultra-light);

  &__toggle {
    margin: $spacing-2 $spacing-1;
    @include round-corners;
    position: absolute;
    top: $spacing-0;
    right: $spacing-0;
    display: none;
    align-self: flex-end;
    background-color: color($white);
    @include elevate-button;

    &--right {
      transform: rotate(-90deg) translateY(73%);
    }

    &--left {
      transform: rotate(90deg) translateY(-73%);
    }
  }

  &:hover .m-main-nav__toggle {
    display: flex;
  }

  &__list {
    max-height: calc(100vh - $spacing-2);
    @include flex(flex-start, stretch, column);
  }

  &__logo {
    margin: $spacing-1 math.div($spacing-3, 2) $spacing-2;
    align-self: flex-start;
    transform: scale(1.17);
    transform-origin: left;
  }

  &__marker {
    width: math.div($spacing-1, 2);
    height: $spacing-6;
    @include round-corners($spacing-1);
    position: absolute;
    left: 0;
    background-color: color($pri-action);
    transform: translateX(-$spacing-1);
  }

  .m-divider {
    opacity: 0.5;
  }

  &__home,
  &__views,
  &__create-view,
  &__view,
  &__template {
    height: $spacing-9;
    margin: $spacing-1 $spacing-0;
    @include round-corners($spacing-1);
    position: relative;
    @include flex(space-between, center, row);
    gap: $spacing-2;

    .h5 {
      color: color($pri-action-inactive);
      @include one-line-ellipsis;
    }

    &--active {
      background-color: color($pri-action, 0.1);
      @include opacity-active;

      .m-icon {
        color: color($pri-action);
        fill: color($pri-action);
        stroke: color($pri-action);
      }

      .h5 {
        font-weight: 700;
        color: color($pri-action);
      }
    }
  }

  &__home {
    justify-content: flex-start;
  }

  &__create-view {
    justify-content: center;
  }

  &__views .d-flex-start-center,
  &__template .d-flex-start-center {
    gap: $spacing-2;
  }

  &__home,
  &__create-view,
  &__views,
  &__template {
    padding: $spacing-1 $spacing-2;

    &:not(.m-main-nav__views):hover {
      .h5,
      .m-icon {
        color: color($pri-action);
        fill: color($pri-action);
      }
    }
  }

  &__views-wrapper {
    width: calc(100% + $spacing-4);
    height: calc(100vh - (($spacing-14 + $spacing-1) * 4));
    max-height: calc(100vh - (($spacing-14 + $spacing-1) * 4));
    padding-left: $spacing-2;
    padding-right: $spacing-2;
    flex-grow: 1;
    transform: translateX(-$spacing-2);
    overflow-x: auto;
  }

  &__view {
    height: $spacing-11;
    padding: $spacing-1;

    > div {
      @include flex(flex-start, center, row);
      gap: $spacing-3;
    }

    .h6.type--xsmall {
      opacity: 0.6;
    }

    .m-view {
      &__badge .m-image {
        width: $spacing-9;
        height: $spacing-9;
        position: relative;
        aspect-ratio: 1 / 1;
        object-fit: cover;
      }

      &__thumbnail {
        border: 1px solid color($pri-action, 0);
        @include round-corners($spacing-1);
        overflow: hidden;
      }

      &__count {
        min-width: $spacing-6;
        height: $spacing-6;
        border: 2px solid color($white);
        @include round-corners($spacing-7);
        @include flex(center, center, row);
        position: absolute;
        right: $spacing-0;
        bottom: $spacing-0;
        transform: translate(math.div($spacing-1, 2), math.div($spacing-1, 2));
        background-color: color($red);

        span {
          font-size: 0.625rem;
          line-height: 0.75rem;
          color: color($white);
        }
      }

      &__actions {
        display: none;
      }
    }

    &:hover {
      .m-view {
        &__thumbnail {
          border: 1px solid color($pri-action, 0.3);
        }

        &__details span.h5 {
          color: color($pri-action);
          fill: color($pri-action);
        }

        &__actions {
          @include flex(flex-start, center, row);
          gap: $spacing-1;
        }
      }
    }
  }

  &--collapsed {
    min-width: $sidenav-width--collapsed;
    width: $sidenav-width--collapsed;
    .m-main-nav {
      &__logo {
        transform: scale(1) translateX(2px);
      }

      &__home,
      &__views,
      &__view,
      &__template {
        padding: $spacing-1;
        @include flex(center, center, row);
        gap: $spacing-0;
      }

      &__views-wrapper {
        height: calc(100vh - ($spacing-12 * 4));
        max-height: calc(100vh - ($spacing-12 * 4));
      }
    }
  }
}

.m-templates__dropdown {
  border: 1px solid color($pri-action-medium, 0.1);
  background-color: color($pri-ultra-light);

  .h6 {
    color: color($pri-action-inactive);
    font-weight: 500;
  }

  .m-icon {
    fill: color($pri-action-inactive);
    stroke-width: 0 !important;
  }
}
</style>
