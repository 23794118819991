<template>
  <div ref="htmlElementRef">
    <div
      :id="`m_main_nav_view_${snakeCase(folder.id)}`"
      class="m-main-nav__view m-clickable"
      @click="toggleOpen"
      ref="htmlElementRef"
    >
      <div>
        <div class="m-view__badge">
          <m-icon
            icon="folder"
            width="32"
            height="32"
            class="m-view__thumbnail"
          />
        </div>
        <div v-if="navState == 'expanded'" class="m-view__details">
          <span class="h5">{{ folder.name }} </span>
          <!-- <span
                    v-else-if="getShareInfo(view)"
                    class="h6 type--xsmall d-flex-start-center"
                  >
                    <m-icon
                      :id="`m_main_nav_view_share_${snakeCase(view.id)}`"
                      :icon="getShareInfo(view).icon"
                      size="xsmall"
                      variant="terciary"
                      weight="light"
                      status="active"
                      class="mr-1"
                    />
                    {{ getShareInfo(view).label }}
                  </span> -->
        </div>
      </div>
      <div v-if="navState == 'expanded'">
        <m-icon
          icon="arrow"
          variant="secondary"
          size="small"
          :class="{
            'rotate-180': isOpened,
          }"
          @click="toggleOpen"
        />
        <!-- <div class="m-view__actions">
          <m-icon
            v-if="canAccessNewsletter(view)"
            :id="`m_main_nav_${snakeCase(view.id)}_newsletter`"
            :icon="newsletterState"
            variant="secondary"
            size="small"
            hover="highlight"
            :data-tooltip-content="newsletterStateTooltip"
            @mouseenter="(evt) => toggleNewsletterState(evt, view)"
            @mouseleave="(evt) => toggleNewsletterState(evt, view)"
            @click="toggleNewsletter(view)"
          />
          <m-icon
            v-if="!view.isBaseView && view.isShared"
            id="m_main_nav_views_leave"
            :tooltip="newsletterTooltip"
            icon="unshare"
            variant="secondary"
            size="small"
            hover="highlight"
            @click="leaveView(view)"
          />
          <m-options
            v-if="!view.isBaseView && !view.isShared"
            id="m_main_nav_views_options"
            variant="secondary"
            :options="viewOptions(view)"
            size="small"
            floating
            disableSort
            class="m-clickable"
            @select="(opt) => viewSelectOpt(view, opt)"
          />
        </div> -->
      </div>
    </div>
    <div class="pl-5 folder-items" :class="{ open: isOpened }">
      <div class="pl-1 sideborder">
        <draggable
          class="dragArea"
          v-model="folderItems"
          item-key="id"
          v-bind="{
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
          }"
        >
          >
          <template #item="{ element }">
            <div>
              <m-main-nav-view-item
                v-if="element.backendId"
                :view="element"
                :key="element.id"
              />
              <m-main-nav-folder-item
                @item-added="bubbleItemAddedEvent"
                @item-removed="bubbleItemRemovedEvent"
                v-else
                :folder="element"
              />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MonitioAPI } from "@root/types.api.local";
import { difference, snakeCase } from "lodash-es";
import MIcon from "../MIcon.vue";
import MMainNavViewItem from "./MMainNavViewItem.vue";
import draggable from "vuedraggable";
import { useSessionStore } from "@root/store/modules/session";
import { computed, onMounted, onUnmounted, ref, watch, type Ref } from "vue";
import type { Monitio } from "@root/types";
import { useUserStore } from "@root/store/modules/user";
const sessionStore = useSessionStore();
const userStore = useUserStore();

interface Props {
  folder: Monitio.WorkspaceUserFolder;
}

const props = defineProps<Props>();
const emits = defineEmits(["itemAdded", "itemRemoved", "itemsChanged"]);

//const isMouseHovering = ref(false);
//const isDragging = ref(false);
const startDrag = () => {
  isOpened.value = true;
};
/* const endDrag = () => {
  console.log("drag eneded");

  isMouseHovering.value = false;
}; */
const htmlElementRef: Ref<HTMLElement | undefined> = ref();
onMounted(() => {
  htmlElementRef.value?.addEventListener("dragenter", startDrag);
  //htmlElementRef.value?.addEventListener("dragleave", endDrag);
});

onUnmounted(() => {
  htmlElementRef.value?.removeEventListener("dragenter", startDrag);
  //htmlElementRef.value?.removeEventListener("dragleave", endDrag);
});

const navState = computed(() => sessionStore.mainNavState);
const isOpened = computed<boolean>({
  get() {
    //if (isDragging.value) return true;
    return props.folder.isOpened ?? false;
  },
  set(val) {
    const folder = userStore.details.folders?.find(
      (x) => x.id == props.folder.id
    );
    if (folder) (folder as Monitio.WorkspaceUserFolder).isOpened = val;
  },
});

const folderItems = computed<(Monitio.WorkspaceUserFolder | Monitio.View)[]>({
  get() {
    return (
      [...(props.folder?.items ?? [])].sort(
        (a, b) =>
          ((a as Monitio.View).order ??
            (a as Monitio.WorkspaceUserFolder).metadata?.order ??
            -1) -
          ((b as Monitio.View).order ??
            (b as Monitio.WorkspaceUserFolder).metadata?.order ??
            -1)
      ) ?? []
    );
  },
  set(val) {
    if ((props.folder.items?.length ?? 0) > val.length) {
      const diffId = difference(props.folder.items, val).at(0)?.id;
      emits("itemRemoved", diffId, props.folder.id);
    } else if ((props.folder.items?.length ?? 0) < val.length) {
      const diffId = difference(val, props.folder.items).at(0)?.id;
      emits("itemAdded", diffId, props.folder.id, val);
    } else {
      emits("itemsChanged", props.folder.id, val);
    }
  },
});

const bubbleItemAddedEvent = (
  diffId: string,
  folderId: string,
  items: (Monitio.View | Monitio.WorkspaceUserFolder)[]
) => {
  emits("itemAdded", diffId, folderId, items);
};
const bubbleItemRemovedEvent = () => undefined;

const toggleOpen = () => {
  isOpened.value = !isOpened.value;
};
</script>

<style scoped lang="scss">
.m-main-nav {
  &__view {
    height: $spacing-9;
    margin: $spacing-1 $spacing-0;
    @include round-corners($spacing-1);
    position: relative;
    @include flex(space-between, center, row);
    gap: $spacing-2;

    .h5 {
      color: color($pri-action-inactive);
      @include one-line-ellipsis;
    }

    &--active {
      background-color: color($pri-action, 0.1);
      @include opacity-active;

      .m-icon {
        color: color($pri-action);
        fill: color($pri-action);
        stroke: color($pri-action);
      }

      .h5 {
        font-weight: 700;
        color: color($pri-action);
      }
    }
  }

  &__view {
    height: $spacing-11;
    padding: $spacing-1;

    > div {
      @include flex(flex-start, center, row);
      gap: $spacing-3;
    }

    .h6.type--xsmall {
      opacity: 0.6;
    }

    .m-view {
      &__badge {
        .m-icon {
          width: $spacing-9;
          height: $spacing-9;
          position: relative;
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }
      }

      &__thumbnail {
        background-color: color($pri-action-light, 0.2);
        border: 1px solid color($pri-action, 0);
        @include round-corners($spacing-1);
        overflow: hidden;
      }

      &__count {
        min-width: $spacing-6;
        height: $spacing-6;
        border: 2px solid color($white);
        @include round-corners($spacing-7);
        @include flex(center, center, row);
        position: absolute;
        right: $spacing-0;
        bottom: $spacing-0;
        transform: translate(math.div($spacing-1, 2), math.div($spacing-1, 2));
        background-color: color($red);

        span {
          font-size: 0.625rem;
          line-height: 0.75rem;
          color: color($white);
        }
      }

      &__actions {
        display: none;
      }
    }

    &:hover {
      .m-view {
        &__thumbnail {
          border: 1px solid color($pri-action, 0.3);
        }

        &__details span.h5 {
          color: color($pri-action);
          fill: color($pri-action);
        }

        &__actions {
          @include flex(flex-start, center, row);
          gap: $spacing-1;
        }
      }
    }
  }
}

.sideborder {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 2px;
    height: 100%;
    background-color: color($pri-action-light);
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.folder-items {
  display: none;

  &.open {
    display: block;
  }
}
</style>
