<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
@use "@stylesheets/scss/main.scss";
@use "@priberam/skeleton-loader/dist/skeleton-loader.css";
@use "../node_modules/shepherd.js/dist/css/shepherd.css";
</style>
